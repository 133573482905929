<template>
  <v-row
    class="fill-height"
    justify="center"
    align="center"
  >
    <v-col
      :lg="4"
      class="px-10 lg:px-0"
    >
      <v-card>
        <v-card-title>{{ COMMON.LABEL_RESET_PASSWORD }}</v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            @submit.prevent="onSubmit"
          >
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="credentials.email"
                  solo
                  label="Email"
                  placeholder="Email address"
                  hide-details="auto"
                  type="email"
                  :rules="[rules.required, rules.email]"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="credentials.password"
                  solo
                  label="Password"
                  placeholder="New Password"
                  type="password"
                  hide-details="auto"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="credentials.rePassword"
                  solo
                  label="Confirm Password"
                  placeholder="Re-enter the password"
                  hide-details="auto"
                  type="password"
                  :rules="[rules.required]"
                />
              </v-col>

              <v-col cols="12">
                <v-btn
                  block
                  class="primary"
                  type="submit"
                >
                  <span v-if="!isBusy">{{ COMMON.LABEL_RESET_PASSWORD }}</span>
                  <v-progress-circular
                    v-else
                    indeterminate
                  />
                </v-btn>
              </v-col>

              <v-col cols="12">
                <v-btn
                  text
                  :disabled="isBusy"
                  @click="$router.push('/login')"
                >
                  {{ COMMON.CANCEL_BTN }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>

    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      absolute
      right
      top
    >
      {{ snackBarText }}
      <template #action="{ attrs }">
        <v-btn
          color="primary"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ COMMON.CLOSE_BTN }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
import common from '@/commons/common';

export default {
  name: 'ForgotPassword',

  props: {
    token: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      COMMON: common,
      ICON: common.ICON,
      isBusy: false,

      snackbar: false,
      snackBarText: 'This is default text!',
      timeout: 3000,

      credentials: {},

      rules: {
        required: (value) => !!value || 'Required.',
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || 'Invalid e-mail.';
        },
      },
    };
  },

  computed: {
    resetToken() {
      return this.token;
    },
  },

  methods: {
    enableSnackbar(text) {
      this.snackbar = true;
      this.snackBarText = text;
    },

    onSubmit() {
      if (this.$refs.form.validate()) {
        this.handleResetPassword();
      }
    },

    async handleResetPassword() {
      try {
        this.isBusy = true;
        const { status } = await this.$axios.post(
          `/secured/requestForgotPassword/${this.resetToken}/changePassword`,
          this.credentials
        );
        if (status === 200) {
          this.enableSnackbar('Password reset successfull!');
          // this.$router.push('/login');
          window.location.href = 'http://newsaudible.in';
        }
      } catch (error) {
        this.enableSnackbar(error.response.data.message);
        this.$refs.form.reset();
      } finally {
        this.isBusy = false;
      }
    },
  },
};
</script>

<style></style>
